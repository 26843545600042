import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { useEffect, useRef, useState } from "react";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";

const SetEvent = (props) => {
  const [arrivalDate, setArrivalDate] = useState();
  const [arrivalTime, setArrivalTime] = useState();

  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();

  const [endDate, setEndDate] = useState();
  const [endTime, setEndTime] = useState();

  const [isSending, setIsSending] = useState(false);
  const [isCompleted, setIsCompleted] = useState(props.event.eventGranted);

  const toast = useRef(null);
  const panelRef = useRef(null);

  const plannedDate = new Date(
    props.event.plannedDateTimeParsed
  ).toLocaleDateString("pl-PL");
  const plannedTime = new Date(
    props.event.plannedDateTimeParsed
  ).toLocaleTimeString("pl-PL", { hour: "2-digit", minute: "2-digit" });

  var minDate = new Date("1900-01-01T00:00:00").getTime();

  useEffect(() => {
    const arrivalDateParsed = new Date(props.event.comeToEventDate);
    if (arrivalDateParsed.getTime() !== minDate) {
      setArrivalDate(arrivalDateParsed);
    }

    const arrivalTimeParsed = new Date(props.event.comeToEventTime);
    if (arrivalTimeParsed.getTime() !== minDate) {
      setArrivalTime(arrivalTimeParsed);
    }

    const startDateParsed = new Date(props.event.startEventDate);
    if (startDateParsed.getTime() !== minDate) {
      setStartDate(startDateParsed);
    }

    const startTimeParsed = new Date(props.event.startEventTime);
    if (startTimeParsed.getTime() !== minDate) {
      setStartTime(startTimeParsed);
    }

    const endDateParsed = new Date(props.event.endEventDate);
    if (endDateParsed.getTime() !== minDate) {
      setEndDate(endDateParsed);
    }

    const endTimeParsed = new Date(props.event.endEventTime);
    if (endTimeParsed.getTime() !== minDate) {
      setEndTime(endTimeParsed);
    }
  }, []);

  const setStatus = async () => {
    setIsSending(true);
    // validacja...
    // Sprawdź czy dane są wprowadzone...

    const errorArray = [];
    if (!arrivalDate) {
      errorArray.push("Nie wprowadzono daty przyjazdu");
    }

    if (!arrivalTime) {
      errorArray.push("Nie wprowadzono godziny przyjazdu");
    }

    if (!startDate) {
      errorArray.push("Nie wprowadzono daty załadunku");
    }

    if (!startTime) {
      errorArray.push("Nie wprowadzono godziny załadunku");
    }

    if (!endDate) {
      errorArray.push("Nie wprowadzono daty wyładunku");
    }

    if (!endTime) {
      errorArray.push("Nie wprowadzono godziny wyładunku");
    }

    if (errorArray.length > 0) {
      toast.current.show({
        severity: "error",
        summary: "Nie nadano statusu",
        content: errorToastTemplate(errorArray),
        life: 5000,
      });
      setIsSending(false);
      return;
    }

    //Sprawdź chronologię...
    const arrivalDateTime = new Date(
      arrivalDate.getFullYear(),
      arrivalDate.getMonth(),
      arrivalDate.getDate(),
      arrivalTime.getHours(),
      arrivalTime.getMinutes(),
      0
    );
    const loadDateTime = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      startTime.getHours(),
      startTime.getMinutes(),
      0
    );
    const unloadDateTime = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      endTime.getHours(),
      endTime.getMinutes(),
      0
    );

    if (loadDateTime < arrivalDateTime) {
      errorArray.push(
        "Data załadunku nie może być mniejsza niż data przyjazdu"
      );
    }

    if (unloadDateTime < loadDateTime) {
      errorArray.push(
        "Data wyładunku nie może być mniejsza niż data załadunku"
      );
    }

    //Sprawdź czy nie data z przyszłości...
    const nowDate = new Date();
    if(nowDate < arrivalDate){
      errorArray.push("Data/godzina przyjazdu nie moze być większa niz obecna data/godzina")
    }

    if(nowDate < loadDateTime){
      errorArray.push("Data/godzina początku zdarzenia nie moze byc wieksza niz obecna data/godzina")
    }

    if(nowDate < unloadDateTime){
      errorArray.push("Data/godzina końca zdarzenia nie moze byc wieksza niz obecna data/godzina")
    }

    if (errorArray.length > 0) {
      toast.current.show({
        severity: "error",
        summary: "Nie nadano statusu",
        content: errorToastTemplate(errorArray),
        life: 5000,
      });
      setIsSending(false);
      return;
    }

    //Jeśli walidacja pomyślna to wyślij na serwer
    const model = {
      Id: props.event.id,
      ComeToEventDate: arrivalDate,
      ComeToEventTime: arrivalTime,
      StartEventDate: startDate,
      StartEventTime: startTime,
      EndEventDate: endDate,
      EndEventTime: endTime,
      type: props.event.type,
      OrderId: props.orderId,
    };

    fetch("/order/addevent", {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((result) => {
      setIsSending(false);
      if (result.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Pomyślnie nadano status",
          detail: "Status został zapisany na serwerze.\r\nDziękujemy",
          life: 5000,
        });
        setIsCompleted(true);
        const panelElement = panelRef.current.getElement();
        const btn = panelElement
          .querySelector(".p-panel-header > .p-panel-icons > .p-panel-toggler")
          .click();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Błąd podczas nadawania statusu",
          detail:
            "Podczas nadawania statusu wystąpił błąd. Spróbuj ponownie, bądź skontaktuj się z nami telefonicznie w tej sprawie",
          life: 5000,
        });
      }
    });
  };

  const errorToastTemplate = (array) => {
    return (
      <div className="flex flex-column align-items-left" style={{ flex: "1" }}>
        <p style={{ fontWeight: "700" }}>
          <i
            className="pi pi-exclamation-triangle mr-2"
            style={{ fontWeight: "700" }}
          ></i>
          Nie nadano statusu
        </p>
        <ul>
          {array.map((x) => {
            return <li key={x}>{x}</li>;
          })}
        </ul>
      </div>
    );
  };

  const headerClasses = "col-12 sm:col-12 md:col-12 lg:col-4 xl:col-4";
  const calendarClasses = "col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4";
  const clockClasses = "col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4";

  return (
    <>
      <Toast ref={toast} />
      <Panel
        ref={panelRef}
        collapsed={isCompleted}
        header={
          props.event.eventNo +
          ". " +
          (props.event.type == "ZA" ? "Załadunek" : "Wyładunek") +
          (isCompleted ? " - Status nadany" : " - Nadaj status")
        }
        style={{ marginTop: "20px" }}
        toggleable
      >
        <p>
          <b>Miejsce </b>
          {props.event.place.replaceAll(";", ",")}
        </p>
        <p>
          <b>Planowana data:</b> {plannedDate}, {plannedTime}
        </p>
        <Divider />
        <div className="grid">
          <div className={headerClasses}>Przyjazd</div>
          <div className={calendarClasses}>
            <Calendar
              value={arrivalDate}
              onChange={(e) => setArrivalDate(e.value)}
              style={{ width: "100%" }}
              showIcon
              showButtonBar
              dateFormat="dd.mm.yy"
              disabled={isCompleted}
            />
          </div>
          <div className={clockClasses}>
            <Calendar
              value={arrivalTime}
              style={{ width: "100%" }}
              onChange={(e) => setArrivalTime(e.value)}
              timeOnly
              showIcon
              icon="pi pi-clock"
              disabled={isCompleted}
              onClick={() => {
                if (!arrivalTime) {
                  setArrivalTime(new Date());
                }
              }}
            />
          </div>
        </div>

        <div className="grid">
          <div className={headerClasses}>
            {props.event.type == "ZA"
              ? "Początek załadunku"
              : "Początek wyładunku"}
          </div>
          <div className={calendarClasses}>
            <Calendar
              value={startDate}
              onChange={(e) => setStartDate(e.value)}
              style={{ width: "100%" }}
              showIcon
              showButtonBar
              dateFormat="dd.mm.yy"
              disabled={isCompleted}
            />
          </div>
          <div className={clockClasses}>
            <Calendar
              value={startTime}
              onChange={(e) => setStartTime(e.value)}
              timeOnly
              showIcon
              icon="pi pi-clock"
              style={{ width: "100%" }}
              onClick={() => {
                if (!startTime) {
                  setStartTime(new Date());
                }
              }}
              disabled={isCompleted}
            />
          </div>
        </div>

        <div className="grid">
          <div className={headerClasses}>
            {props.event.type == "ZA" ? "Koniec załadunku" : "Koniec wyładunku"}
          </div>
          <div className={calendarClasses}>
            <Calendar
              value={endDate}
              onChange={(e) => setEndDate(e.value)}
              style={{ width: "100%" }}
              showIcon
              showButtonBar
              dateFormat="dd.mm.yy"
              disabled={isCompleted}
            />
          </div>
          <div className={clockClasses}>
            <Calendar
              value={endTime}
              onChange={(e) => setEndTime(e.value)}
              style={{ width: "100%" }}
              timeOnly
              showIcon
              icon="pi pi-clock"
              disabled={isCompleted}
              onClick={() => {
                if (!endTime) {
                  setEndTime(new Date());
                }
              }}
            />
          </div>
        </div>
        {!isCompleted && (
          <Button
            label="Nadaj status"
            icon="pi pi-check"
            onClick={setStatus}
            loading={isSending}
          />
        )}
      </Panel>
    </>
  );
};

export default SetEvent;
