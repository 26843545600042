import { Card } from "primereact/card";
import { Slider } from 'primereact/slider';
import { useEffect, useState, useRef } from "react";
import { Tooltip } from 'primereact/tooltip';
import { Button } from "primereact/button";
import { Messages } from 'primereact/messages';
import { getOrder, setDiscount } from "./api/Order";

const Skonto = (props) => {

    const [slider, setSlider] = useState(1);
    const [freight, setFreight] = useState(NaN)
    const [freightCurrency, setFreightCurrency] = useState('No_currency')
    const [skonto, setSkonto] = useState();

    const msgs = useRef(null);

    useEffect(() => {
        setSkonto(skontoValues.get(slider))
        setDiscount(props.order,slider)
    },[slider])

    useEffect(() => {
        setSkonto(skontoValues.get(props.discountState));
        setSlider(props.discountState)
        msgs.current.clear();
        msgs.current.show(
            { sticky: true, severity: 'info', detail: 'Pamiętaj, ze po wybraniu skonta wystawiając fakturę musisz udzielić nam rabatu na jego wartość', closable: false }
        );
        const doAsync = async () => {
            if(props.order){
                const dbOrder = await getOrder(props.order);
                if(dbOrder){
                    setFreight(dbOrder.freightValue);
                    setFreightCurrency(dbOrder.freightCurrency)
                }
            }
        }
        doAsync();
    },[])

    const sliderMin = 1;
    const sliderMax = 4;

    const skontoValues = new Map();
    skontoValues.set(1,{
        value: 0,
        days: 55
    });
    skontoValues.set(2,{
        value: 1.5,
        days: 30
    });
    skontoValues.set(3,{
        value: 3,
        days: 14
    });
    skontoValues.set(4,{
        value: 5,
        days: 3
    })
    

    return(
        <>
            <p>W tym kroku mozesz wybrać skrócony termin płatności - SKONTO</p>
            <div className="grid">
                <div className="col-12 sm:col-12 md:col-12 lg:col-6">
                    <p className="mt-8">Wartość skonta</p>
                    <Tooltip target=".slider>.p-slider-handle" content={`${skontoValues.get(slider).value}%`} position="top" event="focus" />
                    <Slider className="slider" value={slider} onChange={(e) => setSlider(e.value)} min={sliderMin} max={sliderMax} step="1"/>
                    <div className="grid">
                        <div className="col">0%</div>
                        <div className="col text-center" style={{paddingRight: '60px'}}>1,5%</div>
                        <div className="col text-center">3%</div>
                        <div className="col text-right">5%</div>
                    </div>
                    {/* <p>{skonto?.value}%</p> */}
                    {/* <Button label="Zatwierdź skonto" className="mt-4"/> */}
                </div>
                <div className="col-12 sm:col-12 md:col-12 lg:col-6">
                    <Card color="primary">
                        <p style={{fontWeight: 700, fontSize: 'large'}}>Obliczenia</p>
                        <p><b>Termin płatności (netto):</b> {skonto?.days} dni</p>
                        <p><b>Fracht: </b>{freight} {freightCurrency}</p>
                        <p><b>Fracht po skoncie: {(freight - (freight*skonto?.value/100)).toFixed(2)} {freightCurrency}</b></p>
                        <Messages ref={msgs} />
                    </Card>
                </div>
            </div>
        </>
    )
}

export default Skonto;