import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';

const UploadDocsPage = (props) => {

    const [files, setFiles] = useState();
    const [selectedType, setSelectedType] = useState();

    const toast = useRef(null);

    useEffect(() => {
        fetch('/order/getattachments?id='+props.order + "&type=" + props.type).then(x => x.json().then(y => {
            setFiles(y);
        }))
    },[])

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold">Załączone dokumenty</span>
        </div>
    );

    const chooseBtnOptions = {
        label: "Załaduj pliki"
    }

    const uploadBtnOptions = {
        label: "Wyślij na serwer"
    }

    const cancelBtnOptions = {
        label: "Wyczyść"
    }

    const onUpload = (options) => {
        toast.current.show({ severity: 'info', summary: 'Sukces!', detail: 'Plik pomyślnie wysłano na serwer.' });
        setFiles(JSON.parse(options.xhr.response).value);
    }

    const actionsColumnBody = (content) => {
        return (<p style={{color: 'red', fontWeight: '700'}}>Pobierz</p>)
    }

    const appendData = (data) => {
            data.formData.append("formid",props.order);
            data.formData.append("type", props.type);
        }

    return(
    <>
    <Toast ref={toast}></Toast>
    <h5>Załączone dokumenty</h5>
    <DataTable value={files} tableStyle={{ minWidth: '50rem' }} emptyMessage="Brak załączonych dokumentów" size='small'>
        <Column field="fileName" header="Nazwa pliku"></Column>
        <Column body={actionsColumnBody} header="Akcje" style={{width: '200px'}}></Column>
    </DataTable>
    <h5 className='mt-4'>{props.type == "docs" ? "Dodaj dokumenty transportowe" : "Dodaj fakturę"}</h5>

        <div>
            <FileUpload 
            mode="advanced" 
            name="file" 
            url="/order/uploadfile" 
            maxFileSize={100000000} 
            onUpload={onUpload} 
            chooseOptions={chooseBtnOptions} 
            uploadOptions={uploadBtnOptions}
            cancelOptions={cancelBtnOptions}
            onBeforeUpload={appendData}/>
            
        </div>
    </>)
}

export default UploadDocsPage;