export const getOrder = async (orderParams) => {
    const order = await fetch('/order/'+orderParams);
    const orderJson = await order.json();
    orderJson.events = orderJson.events.sort((a, b) => a.eventNo > b.eventNo ? 1 : -1);
    return orderJson;
}

export const setAsFinished = async (orderId) => {
    try{
        if(orderId){
            const status = await fetch('/order/setasfinished?id=' + orderId);
            if(status.ok){
                return true;
            }
            else{
                return "Wystąpił błąd po stonie serwera. Spróbuj ponownie"
            }
        }
        else{
            return "Wystąpił błąd w identyfikatorze zlecenia. Spróbuj ponownie";
        }
    }
    catch{
        return  "Wystąpił błąd podczas zamykania zlecenia. Spróbuj ponownie, lub skontaktuj się z administratorem."
    }
}

export const getOrderId = async(uuid) => {
    if(uuid){
        const response = await fetch('/order/getid?id='+uuid);
        if(response.ok){
            return await response.text()
        }
    }
    return null;
}

export const setDiscount = async(id, type) => {
    const response = await fetch("/order/setdiscount/?id="+id+"&type="+type);
    return response.status;
}