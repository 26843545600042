import React, { Component } from 'react';
import SetStatusPage from './SetStatusPage';
import UploadDocsPage from './UploadDocsPage';
import AllDone from './AllDone';
import { useLocation } from 'react-router-dom';
import { getOrderId } from './api/Order';


export class Home extends Component {
  static displayName = Home.name;



  constructor(){
    super();
    this.state = {
      page: 1,
      orderId: 0
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    this.uuid = params.get('uuid')
    this.getOrderNumber();
    
  }

  async getOrderNumber(){
    if(this.uuid){
      const orderId = await getOrderId(this.uuid);
      if(orderId){
        this.setState({orderId: orderId})
      }
      else{
        this.uuid = null;
      }
    }
  }

  render() {
    if(!this.uuid || this.uuid.length !== 36){
      return(<p>Niepoprawny adres. Spróbuj uzyc linku ze zlecenia.</p>)
    }

    if(this.state.orderId == 0){
      return <p>Ładowanie...</p>
    }


    if(this.state.page === 1){
      return(
        <div className="text-center">
          <h1 className="display-4">Witamy w firmie BRENT</h1>
          <p>Dziękujemy, że z nami jesteś!</p>
          <hr />
          <p>Prosimy o nadanie stausów oraz załączenie wymaganych dokumentów.</p>
          <button className="btn btn-lg btn-dark" onClick={() => {
            this.setState({page:2})
          }}>Przejdź dalej</button>     
        </div>
      )
    }

    if(this.state.page === 2){
      return(
        <SetStatusPage order={this.state.orderId} gotopage={(x) => {
          this.setState({page:x})}}/>
      )
    }

    if(this.state.page === 3){
      return (<AllDone />)
    }
  }
}
