import { Card } from 'primereact/card';
import SetEvent from './SetEvent';
import { useEffect, useState, useRef } from "react";
import { getOrder, setAsFinished } from './api/Order';
import { Button } from "primereact/button";
import UploadDocsPage from './UploadDocsPage';
import { Panel } from 'primereact/panel';
import Skonto from './Skonto';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';

const SetStatusPage = (props) => {

    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const finishBtnRef = useRef(null);

    useEffect(() =>{
        setLoading(true);
        const doAsync = async () => {
            const dbOrder = await getOrder(props.order);

            if(dbOrder){
                setOrder(dbOrder)
                setLoading(false);
            }
        }
        doAsync();
    }, [])


    const setFinished = async () => {
        if(order){
            const response = await setAsFinished(order.id);
            if(typeof(response) === "boolean"){
                toast.current.show({severity:'success', summary: 'Sukces', detail:'Pomyślnie zakończono obsługę zlecenia. Za 3 sekundy zostaniesz przekierowany', life: 3000});
                finishBtnRef.current.disabled = true;
                
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:response, life: 5000});
            }
        }
    }
    if (loading) {
        return <p>Ładowanie....</p>
    }

    return (
        <div>
            <Toast ref={toast} onHide={() => {props.gotopage(3)}}/>
            <h4>Nadaj statusy</h4>
            <p>Przed Tobą bardzo ważny krok. Musisz nadać statusy realizowanego przez Ciebie zlecenia. Jest to niezwykle istotne z punktu widzenia całego procesu. 
            Bardzo dziękujemy za rzetelność przy nadawaniu.</p>

            <Card title="Informacje o zleceniu">
                <p className="m-0">
                    <b>Numer:</b> {order?.orderNumber || "Bład podczas pobierania numeru"}
                </p>
                <p className='m-0'>
                    <b>Numer klienta:</b> {order?.customerOrderNumber || "Błąd podczas pobierania numeru"}
                </p>
            </Card>
            {order?.events.map(x => {
                return(<SetEvent key={x.id} event={x} orderId={props.order}/>)
            })}

            <Panel className='mt-4' header={order?.events.length+1 + ". ePOD | DOKUMENTY TRANSPORTOWE"} toggleable>
                <UploadDocsPage order={props.order} type="docs"/>
            </Panel>

            <Panel className='mt-4 mb-4' header={order?.events.length+2 + ". eFaktura | SKONTO"} toggleable>
                <Skonto order={props.order} discountState={order?.discountState}/>
                <Divider />
                <UploadDocsPage order={props.order} type="invoice"/>
            </Panel>

            <Panel className='mt-4 mb-4' header={order?.events.length + 3 + ". Potwierdź zakończenie realizacji zlecenia"}>
                <p>Jeśli nadałeś juz statusy, załączyłeś dokumenty oraz wybrałeś wartość SKONTA mozesz potwierdzić zakończenie realizacji zlecenia.
                    Od tego momentu załączone dokumenty trafią do weryfikacji, a po jej zakończeniu nadamy termin zapłaty za wykonane zlecenie.
                </p>
                <Button size='lg' label='Potwierdź zakończenie realizacji zlecenia' onClick={setFinished} ref={finishBtnRef}/>
            </Panel>
        </div>
    )
}

export default SetStatusPage;